import "../../assets/css/section.css";
import jakeImage from "../../assets/img/jake-image.png";

function About(mode) {
  const scroll = (anchor) => {
    document
      .getElementById(anchor)
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (
      event.target.slot.slice(0, 6) === "mailto" ||
      event.target.slot.slice(0, 3) === "tel" ||
      event.target.slot.slice(0, 4) === "http"
    ) {
      window.open(event.target.slot);
    } else {
      scroll(event.target.slot);
    }
  };

  return (
    <div>
      <div className="about" id="about">
        <div className="about-image"></div>
        <div className="about-content">
          <div className="section-header gradient">About Me</div>
          <span>
            Hi there! If you're looking for a website, then you've come to the
            right place. I may look (and be) young, but I've been exploring the
            vast world of web design for over 10 years.
          </span>
          <br />
          <br />
          <span>
            My work is{" "}
            <span className="gradient">
              born from passion and shaped by values
            </span>
            .
          </span>
          <br />
          <br />
          <span>
            My partner likes to joke that I was born with a briefcase in my
            hands which, frankly, may be true to a degree. As far back as I can
            remember, I have been passionate about technology and business. This
            passion has driven me to teach myself everything I could to
            replicate real-life business technologies. As a result, I have
            developed a uniquely broad skillset in not only web design, but also
            small business IT solutions as a whole.
          </span>
          <br />
          <br />
          <span>
            Through the myriad of experiences I've had working with various
            teams, both professionally and casually, I have grown to understand
            the importance of, and deeply value,{" "}
            <span className="gradient">
              communication, collaboration, and transparency
            </span>
            {". "}
            These values help us work together so that I can provide both the
            best service and the highest quality websites that I can offer.
            <br />
            <br />
            <span>
              I am incredibly excited to utilize my expertise to provide
              individuals, professionals, small businesses, and organizations
              with quality online presences and high levels of service at a
              price that is fair for both parties. I look forward to working
              with my clients while I continue to develop my portfolio and
              fine-tune my skillset.
            </span>
          </span>
          <div className="about-button-container">
            <div
              className="about-button"
              slot="portfolio"
              onClick={handleClick}
            >
              See my Work
            </div>
            <div className="about-button" slot="contact" onClick={handleClick}>
              Contact Me
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
