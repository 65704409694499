import "../../assets/css/section.css";

import {
  FaMessage,
  FaGlobe,
  FaToolbox,
  FaAppStore,
  FaGear,
  FaMagnifyingGlass,
  FaGoogle,
  FaBullhorn,
} from "react-icons/fa6";

import Card from "../elements/Card";

function Services() {
  return (
    <div className="section" id="services">
      <div className="section-header gradient">Services</div>
      <div className="section-body card-container">
        <Card info>
          <div className="card-icon">
            <FaMessage />
          </div>
          <div className="card-title">Web Consulting</div>
        </Card>

        <Card info>
          <div className="card-icon">
            <FaGlobe />
          </div>
          <div className="card-title">Website Design</div>
        </Card>

        <Card info>
          <div className="card-icon">
            <FaToolbox />
          </div>
          <div className="card-title">Website Maintenance</div>
        </Card>

        <Card info>
          <div className="card-icon">
            <FaAppStore />
          </div>
          <div className="card-title">Web Software Consulting</div>
        </Card>

        <Card info>
          <div className="card-icon">
            <FaGear />
          </div>
          <div className="card-title">Web Software Configuration</div>
        </Card>

        <Card info>
          <div className="card-icon">
            <FaMagnifyingGlass />
          </div>
          <div className="card-title">Search Engine Optimization</div>
        </Card>

        <Card info>
          <div className="card-icon">
            <FaGoogle />
          </div>
          <div className="card-title">Online Presence Management</div>
        </Card>

        <Card info>
          <div className="card-icon">
            <FaBullhorn />
          </div>
          <div className="card-title">Digital Marketing</div>
        </Card>
      </div>
    </div>
  );
}

export default Services;
