import "../../assets/css/section.css";

import Card from "../elements/Card";

import paulfmillerctt from "../../assets/img/paulfmillerctt.png";
import eliteincmn from "../../assets/img/eliteincmn.png";

function Portfolio({ mode }) {
  return (
    <div className="section gradient" id="portfolio">
      <div className="section-header">Portfolio</div>
      <div className="section-content card-container">
        <Card
          image={paulfmillerctt}
          title="Creative Teaching Techniques"
          tags={["Wordpress", "No-code", "Responsive"]}
          liveButton="https://paulfmillerctt.com/"
          liveButtonText="View Here"
          wide
        >
          &nbsp;
        </Card>
        <Card
          image={eliteincmn}
          title="Elite Engineering Inc."
          tags={["SiteJet", "No-code", "Responsive"]}
          liveButton="https://eliteincmn.com/"
          liveButtonText="View Here"
          wide
        >
          &nbsp;
        </Card>
      </div>
    </div>
  );
}

export default Portfolio;
