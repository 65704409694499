import "../../assets/css/section.css";

import Card from "../elements/Card";

import wordpress from "../../assets/img/wordpress.png";
import joomla from "../../assets/img/joomla.png";
import react from "../../assets/img/react.png";
import sitejet from "../../assets/img/sitejet.png";
import htmlcssjs from "../../assets/img/htmlcssjs.jpeg";

function Skills() {
  return (
    <div className="section gradient" id="skills">
      <div className="section-header">Skills</div>
      <div className="section-body card-container">
        <Card
          id="skill"
          image={wordpress}
          title="Wordpress"
          tags={[
            "CMS",
            "Open-source",
            "PHP",
            "Versatile",
            "Popular",
            "Easy Editing",
            "No code",
            "Cloud",
            "Self-host",
          ]}
          Skills
        >
          Wordpress is a popular open-source CMS (Content Management System)
          that powers just over 40% of all websites hosted on the world wide
          web. There are tens of thousands of themes and plugins available for
          use to make any wordpress site your own.
        </Card>
        <Card
          image={joomla}
          title="Joomla"
          tags={[
            "CMS",
            "Open-source",
            "PHP",
            "Versatile",
            "No code",
            "Secure",
            "Cloud",
            "Self-host",
          ]}
          Skills
        >
          Joomla is another open-source CMS that is incredibly versatile and can
          be altered to fit anybody's specific needs. While its system is a bit
          more complex, it is still a user-friendly way to host endless
          possibilities. Like Wordpress, Joomla can be hosted both on the cloud
          or on a local server.
        </Card>
        <Card
          image={react}
          title="React"
          tags={[
            "JavaScript",
            "Code",
            "Cloud",
            "Self-hosted",
            "GitHub Pages",
            "Full Control",
          ]}
          Skills
        >
          React is a JavaScript framework that is used primarily to create web
          apps, but is also an effective tool to create an interactive and/or
          responsive website. For instance, this very site was created using
          React.
        </Card>
        <Card
          image={htmlcssjs}
          title="HTML, CSS, and JavaScript"
          tags={[
            "HTML",
            "CSS",
            "JavaScript",
            "Code",
            "Cloud",
            "Self-hosted",
            "GitHub Pages",
            "Full Control",
            "No-frills",
          ]}
          Skills
        >
          HTML (Hypertext Markup Language) is the bare bones and content of a
          website. CSS (Cascading Stylesheets) gives the website its look and
          feel, it's the 'design' part of web design. JS (JavaScript) helps make
          the website interactive and dynamic. With this combination you can
          make just about any no-frills website.
        </Card>
        <Card
          image={sitejet}
          title="Sitejet"
          tags={["No-Code", "Drag-and-drop", "Easy edits", "Versatile"]}
          Skills
        >
          Sitejet is a simple drag-and-drop website builder included with many
          web hosting services. It is sophisticated enough to create a unique,
          stylish, and effective website but also simple enough that changes are
          a breeze. This is an ideal service for somebody who needs to edit
          their site frequently but doesn't want to build it from scratch.
        </Card>
      </div>
    </div>
  );
}

export default Skills;
