import "../../assets/css/hero.css";

import Navbar from "../elements/Navbar";

function Hero({}) {
  let date = new Date();
  let hour = date.getHours();
  let timeGreeting;
  if (hour > 18) {
    timeGreeting = "Evening";
  } else if (hour > 13) {
    timeGreeting = "Afternoon";
  } else if (hour === 12) {
    timeGreeting = "Day";
  } else {
    timeGreeting = "Morning";
  }

  const scroll = (anchor) => {
    document
      .getElementById(anchor)
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (
      event.target.slot.slice(0, 6) === "mailto" ||
      event.target.slot.slice(0, 3) === "tel" ||
      event.target.slot.slice(0, 4) === "http"
    ) {
      window.open(event.target.slot);
    } else {
      scroll(event.target.slot);
    }
  };

  return (
    <div className="hero-content" id="hero">
      <div className="vertical-center">
        <div className="hero-avatar"></div>
        <div className="hero-title">Good {timeGreeting}, I'm Jake</div>
        <div className="hero-subtitle">I'm so glad you're here.</div>
        <div className="button-container">
          <div className="button" slot="about" onClick={handleClick}>
            Learn More
          </div>
          <div className="button" slot="contact" onClick={handleClick}>
            Request a Quote
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
